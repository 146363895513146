import styled from "@emotion/styled";
import { HIDDEN, LAYER_ONE, LAYER_TWO } from "constants/z-index";
export const StyledSlidingCardComponent = styled.div`
  position: relative;
  height: 100%;

  .card {
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
    height: 100%;
  }

  .card-front-layer {
    opacity: ${({ isFlipped })=>isFlipped ? 0 : 1};
    z-index: ${LAYER_ONE};
  }

  .card-back-layer {
		height: 100%;
    position: absolute;
    opacity: ${({ isFlipped })=>isFlipped ? 1 : 0};
    z-index: ${({ isFlipped })=>isFlipped ? LAYER_TWO : HIDDEN};
  }
`;
